<template>
  <div style="background-color: #2c3e50;height: 100%">
    <div class="mid">
  <div>
  <img src="../../public/img/panda.jpg">
  </div>
  <div class="login">
        <div style="height: 10px;"></div>
        <el-input  type = "text" style="border:0px;background:none;color: white;" name="uname" v-model="uname" placeholder="请输入用户名"/>
        <el-input type = "password" style="border:0px;background:none;color: white;" name="pwd" v-model="pwd" placeholder="请输入密码" show-password/>
        <div style="height: 20px;"></div>
    <el-button style="width: 200px;" v-on:click = "getInfo" type="primary">登录</el-button>
  </div>
  </div>
  </div>
</template>

<script>
import axios from 'axios'
import { ElMessage } from 'element-plus'
export default {
  name: 'Login',
  data () {
    return {
      uname: '',
      pwd: ''
    }
  },
  methods: {
    getInfo () {
      const that = this
      axios.post('/api/hegui/login', {
        uname: that.uname,
        password: that.pwd
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(function (response) {
          console.log(response)
          if (response.data.code === 200) {
            localStorage.setItem('loginName', that.uname)
            that.$router.push('Index')
          } else {
            ElMessage.error(response.data.msg)
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    find_pwd () {
      const that = this
      that.$router.push('/find_pwd')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.mid{
    position:absolute;
    left:50%;
    top:50%;
    margin-left:-200px;
    margin-top:-200px;
    height:400px;
    width:400px;
    text-align: center;
}
h3 {
  margin: 40px 0 0;
}
.login{
    height:100px;
    width:400px;
    margin: 0 auto;
}

a {
  color: #42b983;
}
img {
    width:200px;
    height:200px;
    border-radius:50%;
}
.el-input__inner{
  color: white !important;
}
</style>
