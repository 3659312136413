import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// import 'element-plus/lib/theme-chalk/index.css'
// import axios from 'axios'
// axios.defaults.withCredentials = true
router.beforeEach(function (to, from, next) {
  if (!localStorage.getItem('loginName')) {
    if (to.path !== '/') {
      return next('/')
    }
  }
  return next()
})
createApp(App).use(router).use(ElementPlus).mount('#app')
