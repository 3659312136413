<template>
    <div class="index">
      <div :style="{width: '100vw'}">
        <div :style="{textAlign: 'center',color: 'white',fontSize: '2rem'}">清单内容查询页</div>
         <h3 style="margin-top: 2rem;margin-bottom: 1rem;color:#D3E5F7;font-size: 1.17rem;">&emsp;最近更新时间：{{time}}
         <el-checkbox-group
           style="top: calc(2rem + 10px);position: absolute;left: calc(40%);"
           v-model="checkedCities"
           @change="handleCheckedCitiesChange"
         >
           <el-checkbox v-for="city in cities" :key="city" :label="city">{{
             city
           }}</el-checkbox>
         </el-checkbox-group></h3>
        <div style="text-align: center;font-size: 1rem;"><span style="width: 100px;height: 40px;line-height: 40px;color:#D3E5F7;">实体名称/EntityName：<el-input  type = "text" style="border:0px;background:none;width: 200px;" v-model="name" placeholder="实体名称"/></span>
        &emsp;<span style="width: 100px;height: 40px;line-height: 40px;color:#D3E5F7;">国家/Country
        <el-select v-model = "country" class="m-2" placeholder="国家">
          <el-option
            v-for="item in countryarr"
            :key='item'
            :lable='item'
            :value="item"
        ></el-option></el-select></span>
        &emsp;<span style="width: 100px;height: 40px;line-height: 40px;color:#D3E5F7;">类别 /Type：<el-select v-model = "type" placeholder="类别">
          <el-option
            v-for="item in typearr"
            :key='item'
            :lable='item'
            :value="item"
        ></el-option></el-select></span>
        <span style="width: 100px;height: 40px;line-height: 40px;color:#D3E5F7;">制裁出处/（grounds/program/License review policy)：<el-input  type = "text" style="border:0px;background:none;width: 200px;" v-model="from" placeholder="制裁出处"/></span>
        <!-- <span v-show="false" style="width: 100px;height: 40px;line-height: 40px;color:#D3E5F7;">地址 /Address：<el-input  type = "text" style="border:0px;background:none;width: 200px;" v-model="address" placeholder="地址"/>
        </span> -->
        <div style="height: 1vh;"></div>
       <!-- <el-button style="" type="primary" @click="outMsg()">outmsg</el-button> -->
       <div style="text-align: center">
       <div style="color: white;font-size: 20px;height: 40px;line-height: 40px;float: left;position: absolute;">&emsp;&emsp;&emsp;查询结果/Look up Results: {{record}}</div>
         <!-- <br/> -->
       <el-button style="" type="primary" @click="testCheck()">查询</el-button>&emsp;
       <el-button style="" type="primary" @click="test()">批量查询</el-button>
       <el-button v-show="isAdmin" style="" type="primary" @click="outQuery()">导出查询记录</el-button>&emsp;
       <el-button v-show="isAdmin" style="" type="primary" @click="export2Excel()">导出</el-button>&emsp;
       <el-button type="primary" @click="exportExcel()">导出结果</el-button>
      <div style="height: 1vh;"></div>
      <el-dialog
          v-model="isMany"
          title="批量查询"
          width="30%"
          :before-close="handleClose"
        >
       <el-upload
           class="upload-demo"
           action="https://jsonplaceholder.typicode.com/posts/"
           :on-preview="handlePreview"
           :on-remove="handleRemove"
           :before-remove="beforeRemove"
           :on-success="successUp"
           multiple
           :limit="3"
           :on-exceed="handleExceed"
           :file-list="fileList"
         >
         <el-button size="small" type="primary">点击上传文件</el-button>
         <!-- <template #tip>
           <div class="el-upload__tip">
             jpg/png files with a size less than 500kb
           </div>
         </template> -->
       </el-upload>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="isMany = false">取消</el-button>
              <el-button type="primary" @click="queryMany()"
                >查询</el-button>
            </span>
          </template>
        </el-dialog>
      <el-table
       :id="tabid"
       :data="devices"
       border
       height="calc(97vh - 8.17rem - 120px)"
       color="#D3E5F7"
       style="width: 90%;margin:0 auto;font-size:1rem;bottom: 1rem;position: absolute;left: 5%;"
       empty-text="your search has not returned any result"
       >
        <el-table-column
            prop="country"
            label="国家/Country"
            width="180px"
          >
        </el-table-column>
        <el-table-column
            prop="name"
            label="实体名称/EntityName"
            width="200px"
          >
        </el-table-column>
        <el-table-column
            prop="address"
            label="地址/Address"
          >
        </el-table-column>
        <el-table-column
            prop="type"
            label="类别/Type"
            width="120px"
          >
        </el-table-column>
        <el-table-column
            prop="from1"
            label="制裁出处/（grounds/program/License review policy)"
            width="240px"
          >
        </el-table-column>
        <el-table-column
            prop="listtype"
            label="清单种类/ListType"
            width="120px"
          >
        </el-table-column>
        <el-table-column
            prop="fromDate"
            label="开始日期/FromDate"
            width="120px"
          >
        </el-table-column>
        <el-table-column
            prop="toDate"
            label="结束日期/ToDate"
            width="120px"
          >
        </el-table-column>
       </el-table>
        </div>
        </div>
       <!-- <div class="pagination" style="text-align: center;">
         <el-pagination
           @current-change="handleCurrentChange"
           :current-page="currentPage"
           :page-size="10"
           layout="total, prev, pager, next, jumper"
           :total="total">
         </el-pagination>
        </div> -->
      <el-table
      :id="queryid"
       :data="queryData"
       border
       v-show="false"
       color="#D3E5F7"
       height="80%"
       style="width: 90%;margin:0 auto;font-size:20px;"
       empty-text="your search has not returned any result"
       >
       <el-table-column
           prop="id"
           label="序号/id"
           width="180px"
         >
       </el-table-column>
       <el-table-column
           prop="username"
           label="用户名/User"
           width="200px"
         >
       </el-table-column>
        <el-table-column
            prop="country"
            label="国家/Country"
            width="180px"
          >
        </el-table-column>
        <el-table-column
            prop="name"
            label="实体名称/EntityName"
            width="200px"
          >
        </el-table-column>
        <el-table-column
            prop="address"
            label="地址/Address"
          >
        </el-table-column>
        <el-table-column
            prop="type"
            label="类别/Type"
            width="120px"
          >
        </el-table-column>
        <el-table-column
            prop="from1"
            label="制裁出处/（grounds/program/License review policy)"
            width="240px"
          >
        </el-table-column>
        <el-table-column
            prop="listtype"
            label="清单种类/ListType"
            width="120px"
          >
        </el-table-column>
        <el-table-column
            prop="time"
            label="时间/Time"
            width="120px"
          >
        </el-table-column>
       </el-table>
    </div>
    </div>
</template>
<script>
import { ElMessage } from 'element-plus'
import { saveAs } from 'file-saver'
import XLSX from 'xlsx'
import axios from 'axios'
export default {
  data () {
    return {
      heightTop: '40vh',
      heightTable: '60vh',
      record: 0,
      queryData: [{}],
      queryid: 'queryid',
      isAdmin: false,
      isMany: false,
      tabid: 'query-data',
      address: '',
      time: '2022-03-28',
      name: '',
      type: '',
      country: '',
      typearr: [
        'Entity',
        'Individual'
      ],
      countryarr: [
        'Afghanistan',
        'Albania',
        'Algeria',
        'Angola',
        'Argentina',
        'Armenia',
        'Aruba',
        'Australia',
        'Austria',
        'Azerbaijan',
        'Bahamas, The',
        'Bahrain',
        'Bangladesh',
        'Barbados',
        'Belarus',
        'Belgium',
        'Belize',
        'Benin',
        'Bolivia',
        'Bosnia and Herzegovina',
        'Brazil',
        'Brunei',
        'Bulgaria',
        'Burkina Faso',
        'Burma',
        'Cambodia',
        'Canada',
        'Cayman Islands',
        'Central African Republic',
        'Chile',
        'China',
        'Colombia',
        'Comoros',
        'Congo, Democratic Republic of the',
        'Congo, Republic of the',
        'Costa Rica',
        'Cote d Ivoire',
        'Croatia',
        'Cuba',
        'Cyprus',
        'Czech Republic',
        'Denmark',
        'Dominica',
        'Dominican Republic',
        'Ecuador',
        'Egypt',
        'El Salvador',
        'Equatorial Guinea',
        'Eritrea',
        'Estonia',
        'Ethiopia',
        'Finland',
        'France',
        'Georgia',
        'Germany',
        'Ghana',
        'Gibraltar',
        'Greece',
        'Guatemala',
        'Guernsey',
        'Guyana',
        'Haiti',
        'Honduras',
        'Hong Kong',
        'India',
        'Indonesia',
        'Iran',
        'Iraq',
        'Ireland',
        'Israel',
        'Italy',
        'Jamaica',
        'Japan',
        'Jersey',
        'Jordan',
        'Kazakhstan',
        'Kenya',
        'Korea, North',
        'Korea, South',
        'Kosovo',
        'Kuwait',
        'Kyrgyzstan',
        'Laos',
        'Latvia',
        'Lebanon',
        'Liberia',
        'Libya',
        'Liechtenstein',
        'Luxembourg',
        'Macau',
        'Malaysia',
        'Maldives',
        'Mali',
        'Malta',
        'Marshall Islands',
        'Mauritania',
        'Mexico',
        'Moldova',
        'Mongolia',
        'Montenegro',
        'Morocco',
        'Mozambique',
        'Namibia',
        'Netherlands',
        'Netherlands Antilles',
        'New Zealand',
        'Nicaragua',
        'Niger',
        'Nigeria',
        'Norway',
        'Oman',
        'Pakistan',
        'Palau',
        'Palestinian',
        'Panama',
        'Paraguay',
        'Peru',
        'Philippines',
        'Poland',
        'Qatar',
        'Region: Crimea',
        'Region: Gaza',
        'Region: Kafia Kingi',
        'Region: Northern Mali',
        'Romania',
        'Russia',
        'Rwanda',
        'Saint Kitts and Nevis',
        'Saint Vincent and the Grenadines',
        'Samoa',
        'Saudi Arabia',
        'Senegal',
        'Serbia',
        'Seychelles',
        'Sierra Leone',
        'Singapore',
        'Slovakia',
        'Slovenia',
        'Somalia',
        'South Africa',
        'South Sudan',
        'Spain',
        'Sri Lanka',
        'Sudan',
        'Sweden',
        'Switzerland',
        'Syria',
        'Taiwan',
        'Tajikistan',
        'Tanzania',
        'Thailand',
        'The Gambia',
        'Trinidad and Tobago',
        'Tunisia',
        'Turkey',
        'Turkmenistan',
        'Uganda',
        'Ukraine',
        'undetermined',
        'United Arab Emirates',
        'United Kingdom',
        'United States',
        'Uruguay',
        'Uzbekistan',
        'Vanuatu',
        'Venezuela',
        'Vietnam',
        'Virgin Islands, British',
        'West Bank',
        'Yemen',
        'Zimbabwe'
      ],
      from: '',
      checkAll: false,
      checkedCities: [],
      isIndeterminate: false,
      search: '',
      cities: ['实体清单', '特别指定国民清单', '世行黑名单'],
      // devices: null,
      devices: null,
      tabData: [],
      fileList: [],
      queryManyData: [{}]
    }
  },
  mounted () {
    this.isAdminf()
    this.getTime()
  },
  props: [
    'searchArr'
  ],
  methods: {
    getTime () {
      const that = this
      axios.get('/api/hegui/gettime', {
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(function (response) {
          console.log(response)
          if (response.data.code === 200) {
            that.time = response.data.data[0].time
          } else {
            ElMessage.error(response.data.msg)
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    isAdminf () {
      console.log(localStorage.getItem('loginName'))
      if (localStorage.getItem('loginName') === 'uaide') {
        this.isAdmin = true
      }
    },
    outQuery () {
      const that = this
      axios.post('/api/hegui/out', {
        user: localStorage.getItem('loginName')
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(function (response) {
          if (response.data.code === 200) {
            console.log(response)
            console.log(response.data)
            that.record = response.data.data.length
            that.queryData = response.data.data
          } else {
            ElMessage.error(response.data.msg)
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    successUp (response, file, fileList) {
      this.fileList = fileList
      this.upload(file, this.fileList)
    },
    handleRemove (file, fileList) {
      this.fileList = fileList
    },
    handlePreview (file) {
      console.log('preview')
      console.log(file)
    },
    handleExceed (files, fileList) {
      this.$message.warning(
        `The limit is 3, you selected ${
          files.length
        } files this time, add up to ${files.length + fileList.length} totally`
      )
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`删除文件 ${file.name} ?`)
    },
    upload (file, fileList) {
      console.log(file, 'file')
      console.log(fileList, 'fileList')
      const files = { 0: file.raw }// 取到File
      this.readExcel(files)
    },
    readExcel (files) { // 表格导入
      const that = this
      console.log(files)
      if (files.length <= 0) { // 如果没有文件名
        return false
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$Message.error('上传格式不正确，请上传xls或者xlsx格式')
        return false
      }
      const fileReader = new FileReader()
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result
          const workbook = XLSX.read(data, {
            type: 'binary'
          })
          const wsname = workbook.SheetNames[0]// 取第一张表
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname])// 生成json表格内容
          console.log(ws, 'ws是表格里的数据，且是json格式')
          that.tabData = ws
          this.queryMany(ws)
        } catch (e) {
          return false
        }
      }
      console.log(fileReader.readAsBinaryString(files[0]))
    },
    handleCurrentChange (val) {
      console.log(val)
    },
    diy () {
    },
    export2Excel () {
      const wb = XLSX.utils.table_to_book(document.querySelector('#queryid'))
      /* get binary string as output */
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      try {
        saveAs(new Blob([wbout], { type: 'application/octet-stream' }), '查询记录.xlsx')
      } catch (e) {
        if (typeof console !== 'undefined') {
          console.log(e, wbout)
        }
      }
      return wbout
    },
    exportExcel () {
    /* generate workbook object from table */
      const wb = XLSX.utils.table_to_book(document.querySelector('#query-data'))
      /* get binary string as output */
      console.log(wb)
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      try {
        saveAs(new Blob([wbout], { type: 'application/octet-stream' }), '查询结果.xlsx')
      } catch (e) {
        if (typeof console !== 'undefined') {
          console.log(e, wbout)
        }
      }
      return wbout
    },
    test () {
      this.isMany = true
    },
    testCheck () {
      // var that = this
      const that = this
      axios.post('/api/hegui/get', {
        country: that.country,
        name: that.name,
        address: that.address,
        type: that.type,
        listtype: that.checkedCities.toString(),
        from1: that.from,
        uname: localStorage.getItem('loginName')
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(function (response) {
          if (response.data.code === 200) {
            that.record = response.data.data.length
            that.devices = response.data.data
          } else {
            that.devices = null
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    queryMany () {
      this.isMany = false
      var ws = this.tabData
      const that = this
      var queryManyData = []
      var temp = 0
      console.log(ws.length)
      for (var i = 0; i < ws.length; i++) {
        axios.post('/api/hegui/getmany', {
          name: ws[i].name,
          country: '',
          from1: '',
          type: '',
          listtype: ''
          /* country: ws[i].country
          from1: ws[i].from,
          type: ws[i].type,
          listtype: ws[i].listtype1,
          from1: ws[i].from */
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(function (response) {
            temp = temp + 1
            // console.log(response)
            if (response.status === 200) {
              queryManyData = queryManyData.concat(response.data.data)
              console.log(temp, ws.length)
              if (temp === ws.length - 1) {
                that.devices = queryManyData
                that.record = that.devices.length
              } else {
              }
            } else {
              ElMessage.error(response.data.msg)
            }
          })
          .catch(function (error) {
            console.log(error)
          })
      }
    },
    outMsg () {
      // var that=this
    },
    handleCheckAllChange () {
      if (this.isIndeterminate === false) {
        this.checkedCities = this.cities
        this.isIndeterminate = true
      } else {
        this.checkedCities = []
        this.isIndeterminate = false
      }
    },
    handleCheckedCitiesChange () {
    }
  }
}
</script>
<style>
  .index {
    background-image: url(../assets/background.png);
    background-color: #000000;
    height: 100vh;
    display: flex;
  }
  .el-checkbox{
    color: white !important;
  }
  h1{
    color: white;
    text-align: center;
  }
  .el-table, .el-table__expanded-cell {
    background-color: transparent !important;
  }
  .el-table tr {
      background-color: transparent !important;
  }
  .el-table th, .el-table tr {
    background-color: transparent !important;
  }
  .el-table__body tr.hover-row.current-row>td, .el-table__body tr.hover-row.el-table__row--striped.current-row>td, .el-table__body tr.hover-row.el-table__row--striped>td, .el-table__body tr.hover-row>td
  {
    background-color: transparent !important;
  }
  .el-input__inner{
    background-color: #0B1D34 !important;
    color: white;
  }
  .el-table .el-table__body tr:hover td {
    background-color: darkblue !important;
  }
  .el-table td.el-table__cell div{
    color:#D3E5F7;
  }
  .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf{
    color: #D3E5F7;
  }
/*  ::-webkit-scrollbar {
    width: 12px;
    height: 20px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #a1a3a9;
    border-radius: 5px;
  }
  .demo ::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    width: 12px;
    height: 20px;
  }
  .demo  ::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: #a1a3a9;
    border-radius: 5px;
  } */
</style>
