<template>
  <div id="nav" style="height: 100vh;">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  }
}
</script>

<style>

#nav {
  padding: 0px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
